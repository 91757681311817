
.modalEdit {
  animation-name: modalEditAnimation;
  animation-duration: 0.8s;
}

@keyframes modalEditAnimation {
  0% {
    transform: translateY(100vh);
  }
}

.cardQuestionsAnimation{
  animation-name: cardQuestions;
  animation-duration: 2s;
}

@keyframes cardQuestions {
  0% {
    height: 106px;
  }
  100% {
    height: auto;
  }
}

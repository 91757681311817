.hoverButton:hover {
  opacity: 0.5;
  transition: 0.3s;
}
.hoverButton {
  transition: 0.3s;
}

.sidebarOptions {
  color: var(--grey-5);
}
.sidebarOptions:hover {
  background-color: var(--hover);
  color: var(--color-primary);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

p {
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Primary Palette */
  --color-primary: #000000;
  --background: #e5e5e5;
  --backgroundDashboard: #f7f9fb;
  --negative: #ea0000;

  /* Grey Scale Palette */
  --white: #ffffff;
  --grey-0: #d7d7d7;
  --grey-1: #a0a0a0;
  --grey-2: #3C3C43;
  --grey-3: #787486;


  --hover: #f7f9fb;

  /* Typography */
  --title-1: 1.5rem;
  --title-2: 0.9375rem;
  --content: 0.875rem;
  --content-2: 0.8125rem;
  --button: 1.125rem;
  --button-2: 0.9375rem;

  /* Cart */
  --amount: 0.625rem;
}

@font-face {
  font-family: Popins-regular;
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Popins-Medium;
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Popins-SemiBold;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Comforta-Regular;
  src: url("./fonts/Comfortaa/Comfortaa-Regular.ttf");
}

@font-face {
  font-family: Great-Vibes;
  src: url("./fonts/Great/GreatVibes-Regular.ttf");
}

.textPrimaryLanding {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  font-family: Popins-regular;
  color: var(--color-primary);
  line-height: normal;
  letter-spacing: 1px;
}

.textPrimaryLabelInput {

  font-family: Popins-regular;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;

}

.textTitleInitial {
  font-family: Popins-regular;
  font-weight: 400;
  font-size: 1.875rem;
  color: #000;
}

.textDescriptionInitial {

  font-family: Popins-regular;
  font-weight: 400;
  font-size: 1.3125rem;
  color: #000;
  line-height: 1.3125rem;

}

.textPrimaryInput {

  font-family: Popins-regular;
  font-weight: 500;
  font-size: 0.975rem;
  padding-left: 0.938rem;
  color: #5c5b5b;
outline: none;
}

.textErrorInput {
  color: red;
  font-family: Popins-regular;
  position: absolute;
  top: 87px;
  font-size: 0.975rem;

}

.textPrimaryButton {

  color: white;
  font-family: Popins-regular;
  font-weight: 400;
  font-size: 16px;

}

.textForgotPassword {
  color: var(--color-primary);
  font-family: Popins-regular;
}

.textPrimaryForgotPassword {
  margin-top: 2.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.0625rem;

  font-family: Popins-regular;
  color: var(--color-primary)
}

.textDescriptionForgotPassword {
  font-family: Popins-regular;
  font-weight: 400;
  font-size: 15px;
  color: var(--black);
  margin-bottom: 2.125rem;
}

.textPrimarySendMail {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.0625rem;
  text-align: center;

  font-family: Popins-regular;
  color: var(--color-primary)
}

.textDescriptionSendMail {
  font-family: Popins-regular;
  font-weight: 400;
  font-size: 15px;
  color: var(--black);
  text-align: center;
}

.textPrimaryResetPassword {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.0625rem;

  font-family: Popins-regular;
  color: var(--color-primary)
}


.slider {
  height: 50px;
}

.textPrimaryRegister {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.0625rem;

  font-family: Popins-regular;
  color: var(--color-primary)
}

.textDescriptionResetPassword {
  font-family: Popins-regular;
  font-weight: 400;
  font-size: 15px;
  color: var(--black);
  margin-bottom: 1.6875rem;
}

.textPrimaryLabelInput {
  font-family: Popins-regular;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;
}

.textLabelInputSelectFilterManager {
  font-family: Popins-regular;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 14px;
}

.textTitleTermsRegister {
  font-family: Popins-regular;
  color: black;
  font-weight: 600;
  font-size: 0.875rem;
}

.textDescriptionTermsRegister {
  font-family: Popins-regular;
  color: black;
  font-weight: 500;
  font-size: 0.8125rem;
}

.textTermsModalRegister {
  font-family: Popins-regular;
  color: var(--color-primary);
  font-weight: 600;
  font-size: 0.875rem;
}

.textFooterRegisterTitle {
  font-family: Popins-regular;
  color: var(--grey-2);
  font-weight: 500;
  font-size: 0.9375rem;
}

.textFooterRegisterContent {
  font-family: Popins-regular;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 0.9375rem;
}

.textCheckedSidebar {
  font-family: Popins-regular;
  color: var(--white);
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
}

.textNotCheckedSidebar {
  font-family: Popins-regular;
  color: var(--grey-3);
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0;
}

.textTitleCardQuotation {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 16px;
}

.textDescriptionCardQuotation {
  font-family: Popins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.textTitleTypeDispatch {
  font-family: Popins-regular;
  color: #000000;
  font-weight: 600;
  font-size: 17px;
}

.textDescriptionTypeDispatch {
  font-family: Popins-regular;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
}

.textPrimaryHeaderDashboard {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 30px;
  color: var(--color-primary);
}

.textClientInfoHeader {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 16px;
  color: var(--grey-3);
}

.textUploadImg {
  font-family: Popins-Medium;
  color: #000000;
  font-weight: 500;
  font-size: 0.75rem;
}

.textUploadImgType {
  font-family: Popins-Medium;
  color: #000000;
  font-weight: 400;
  font-size: 0.625rem;
}

.textAddNewRoute {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 15px;
  color: var(--color-primary);
}

.textRemoveNewRoute {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 15px;
  color: #EA0000;
}

.textTitleSummary {
  font-family: Popins-regular;
  font-weight: 600;
  font-size: 15px;
  color: #6A6A6A;
}

.textPrimaryLoginAdm {
  margin-top: 2.125rem;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 2.5rem;

  font-family: Popins-regular;
  color: #123164
}

.textTitleShippingCard {
  font-family: Popins-regular;
  font-size: 0.875remx;
  font-weight: 600;
  color: #000000;
}

.textTypeShippingCard {
  font-family: Popins-regular;
  font-size: 0.875remx;
  font-weight: 500;
  color: #808080;
}

.textTypeValueShippingCard {
  font-family: Popins-regular;
  font-size: 0.875rem;
  font-weight: 500;
  color: #000000;
}

.textModalTitleOption {
  font-family: Popins-regular;
  font-size: 17px;
  font-weight: 600;
  color: #000000;
}

.textModalContentOption {
  font-family: Popins-regular;
  font-size: 17px;
  font-weight: 600;
  color: #EA0000;
}

.textModalContentOptionEdit {
  font-family: Popins-regular;
  font-size: 17px;
  font-weight: 600;
  color: #000;
}

.textNameTableListUser {

  font-family: Popins-Medium;
  color: #6A6A6A;
  font-size: 0.87rem;
  text-align: center;
  width: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(5px);
  position: relative;
  top: -6px;

}

.textNameTableListUserDash {

  font-family: Popins-Medium;
  color: #6A6A6A;
  font-size: 0.87rem;
  text-align: center;
  width: 220px;
  position: relative;
  top: -6px;

}

.textPrimaryModalFilterManager {
  font-family: Popins-Medium;
  font-size: 1.125rem;
  margin-left: 18px;
}

.textModalTableListUserEditPrimary {
  font-family: Popins-Medium;
  color: #030303;
  font-size: 1.12rem;
}

.textModalTableListUserEditSecondary {
  font-family: Popins-Medium;
  color: #EA0000;
  font-weight: 500;
  font-size: 1.12rem;
}

.modal-filter-table-list-user-edit {
  width: 24.414vw;
  max-width: 24.414vw;
  border-radius: 20px;
}

.modal-filter-table-list-user {

  width: 74.414vw;
  max-width: 46vw;
}

.errorMessage {

  font-family: Popins-regular;
  color: red;
  font-size: 0.9rem;
  position: absolute;
  top: 84px;
  left: 2px;

}

.textBtnFilterManagerModal {
  font-family: Popins-Medium;
  font-size: 0.938rem;
  color: white;

}

.textConfirmationRemove {
  font-family: Popins-regular;
  font-size: 1.12rem;
  text-align: center;
  margin-top: 1.37rem;
}

.textBtnRemove {

  font-family: Popins-Medium;
  color: #EA0000;
  font-size: 0.93rem;
}

.textPrimaryModalFilterManager {

  font-family: Popins-Medium;
  font-size: 1.125rem;
  margin-left: 18px;
}

.textBtnFilterManager {

  color: white;
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 15px;
}

.textTitleCardBlackList {
  color: #101828;
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 14px;
}

.textTypeCardBlackList {
  color: #808080;
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 14px;
}

.textContentCardBlackList {
  color: #1A1A1A;
  font-family: Popins-Medium;
  font-weight: 500;
  font-size: 14px;
  max-width: 232px;
  text-align: end;
}

.textTitleSelectUser {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

.textTitleSelectUserSelect {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: #6A6A6A;
}

.textTitleSelectUserOption {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.textTilteRecommended {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.textCompanyTitleCard {
  font-family: Popins-regular;
  font-size: 14px;
  font-weight: 400;
  color: #060607;
}

.textCompanyTitleCardModal {
  color: #060607;
  font-size: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}

.textCompanyTitleCardDetails {
  color: #808080;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.textCompanyDescriptionCard {
  font-family: Popins-regular;
  font-size: 11px;
  font-weight: 400;
  color: #060607;
  margin-bottom: 10px;
}

.textCompanyDiscount {
  font-family: Popins-Medium;
  font-size: 10px;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.textCompanyDiscountModal {
  font-family: Popins-Medium;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.textTitleCloseModal {
  font-family: Popins-Medium;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.textCompanyDescriptionModal {
  font-family: Popins-Medium;
  font-size: 0.75rem;
  font-weight: 400;
  color: #1F1F1F;
}

.textTitleFormModal {
  font-family: Popins-Medium;
  font-size: 1.0625rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;
}


.textTitleConfirmedInterest {
  font-family: Popins-Medium;
  font-size: 21px;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 14px;
}

.textDescriptionConfirmedInterest {
  font-family: Popins-Medium;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #000;
  margin-bottom: 35px;

}

.textBtnConfirmedInterest {
  font-family: Popins-Medium;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  cursor: pointer;
}

.textProposalNegotiations {
  font-family: Popins-Medium;
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
  cursor: pointer;
}

.textStatusContract {
  font-family: Popins-Medium;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #808080;
  cursor: pointer;
}

.textSelectCompanyHeader {
  font-family: Popins-Medium;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-primary);
}

.textCardJourneyTitle {
  font-family: Popins-Medium;
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
}

.textCardJourneyContent {
  font-family: Popins-Medium;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6A6A6A;
}

.textTitleFormJourney {
  font-family: Popins-Medium;
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
}

.textTitleCardPartnersAdm {
  font-family: Popins-Medium;
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
}

.textContenteCardPartnersAdm {
  font-family: Popins-Medium;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #1A1A1A;
  word-wrap: break-word;
}

.btnContenteCardPartnersAdm {
  font-family: Popins-Medium;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #1A4297;
}

.textTitleEditPage {
  font-family: Popins-Medium;
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
}

.textTitleContract {
  font-family: Popins-Medium;
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
}

.textTitlePartnerPageCard {
  font-family: Popins-Medium;
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
}

.textContentPartnerPageCard {
  font-family: Popins-Medium;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1A1A1A;
}

.signatureOn {

  background-color: #E0FFF0;
  padding: 7px 14px;
  border: 1px solid transparent;
  border-radius: 60px;
  font-size: 13px;
  width: 180px;
  outline: none;
  color: #00B386;
}

.signatureOff {
  background-color: #FFF7AA;
  padding: 7px 14px;
  border: 1px solid transparent;
  border-radius: 60px;
  font-size: 13px;
  width: 180px;
  outline: none;
  color: #C6B200;
}

.textTitleCardPercent {
  font-family: Popins-Medium;
  font-size: 1.0625rem;
  font-weight: 500;
  color: #000000;
}

.textContentPercentCard {
  font-family: Popins-Medium;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: #787486;
}

.textCreateAccountTitle {
  color: rgba(60, 60, 67, 0.72);
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textCreateAccountContent {
  color: #102033;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.textBoldBtn {
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textBoldBtnLower {
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 90px;
}

.textPriceTitle {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textPriceContent {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textPriceDetails {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.signatureContract {
  font-family: Great-Vibes;
  font-size: 25px;
}

.signatureContractDetails {
  font-family: Popins-regular;
  font-size: 14px;
}

.signatureContractDetailsTitleFiles {
  font-family: Popins-regular;
  font-size: 16px;
  font-weight: 600;
}

.signatureContractDetailsFiles {
  font-family: Popins-regular;
  font-size: 14px;
  color: #102033;
  cursor: pointer;
  text-align: start;
}

.signatureContractDetailsFiles:hover {
  text-decoration: underline;
}

.signatureContractDetailsTitle {
  font-family: Popins-SemiBold;
  font-size: 14px;
}

.percentageScore {
  font-family: Popins-SemiBold;
  font-size: 20px;
}

.textMessageScore {
  font-family: Popins-regular;
  font-size: 14px;
}

.textImportance {
  font-family: Popins-regular;
  font-size: 11px;
}

.textTitleContractualParameter {
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.proposta{
  text-decoration: none;
  color: #fff;
  background-color: #123164e4;
  padding: 10px 60px;
  border-radius: 10px;
  cursor: pointer;
}

.textTitle-login {
  color: #000;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.9px;
}

.textSubTitle-login {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.63px;
  text-align: left;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  color: #6A6A6A;
}

td {
  padding: 18px;
  background: white;
  border: solid 1px #D7D7D7;
  border-style: solid none solid none;
  vertical-align: middle;
}

tr:first-child td:first-child {
  border: solid 1px #D7D7D7;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-style: solid none solid solid;
}

tr:first-child td:last-child {
  border: solid 1px #D7D7D7;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: solid solid solid none;
}

input[type="checkbox"]{
  cursor: pointer;
}

input[type="checkbox"]:checked{
  appearance: none;
  background-color: var(--color-primary);
}

.modal-filter-option {
  
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px;
  background: #D8D8D8;
  border-radius: 50px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #D8D8D8;
  border-radius: 10px;
  height: 35px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 10px;
  max-width: 10;
}

.containerBox {
  padding-left: 3.375rem;
}

.btnStateTrail {
  min-width: 190px;
}

@media (max-width: 1326px) {
  .containerBox {
    padding-right: 2.125rem;
  }
}

@media (min-width: 900px) {
  .btnState {
    width: 100px;
  }

  .containerBox {
    width: 100%;
  }

  .cardProgress {
    width: 30%;
    height: auto;
  }

  .cardQuestions {
    width: 68%;
    height: 266px;
  }

  .cardRecommended {
    width: 50%;
    height: 582px;
  }

  .cardOpportunities {
    width: 50%;
    height: 582px;
  }

}

@media (min-width: 1000px) {
  .btnState {
    width: 120px;
  }
}

@media (min-width: 1200px) {
  .btnState {
    width: 140px;
  }
}

@media (min-width: 1326px) {

  .btnState {
    width: 150px;
  }

  .boxContainerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .boxContainerCard {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerCard {
    max-width: 1000px;
  }

  .containerHeader {
    width: 1000px;
  }

  .containerBox {
    width: 1000px;
    margin: 0 auto;
    padding-left: 0;
  }
}

@media (min-width: 1426px) {

  .btnState {
    width: 180px;
  }

  .boxContainerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .boxContainerCard {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerCard {
    max-width: 1100px;
  }

  .containerHeader {
    width: 1100px;
  }

  .containerBox {
    width: 1100px;
    margin: 0 auto;
    padding-left: 0;
  }
}